<template>
    <section id="CreateWager">
        <BookSelector :books="managedBooks" :selectedName="profile.books[$route.params.book_id].name" />
        <Wager />
        <Options :options="options" :selected="1" />
    </section>
</template>

<script>

    // Components.
    import BookSelector from '@/components/BookSelector.vue';
    import Options from '@/components/Options.vue';
    import Wager from '@/components/Wager.vue';

    // Misc.
    import { MANAGE_OPTIONS } from '@/constants.js';

    export default {
        name: 'CreateWager',
        components: {
            BookSelector,
            Options,
            Wager,
        },
        mounted() {
            this.$store.dispatch('setNavShadow', true);
        },
        computed: {
            managedBooks() {
                return Object.entries(this.profile.books).filter(book => book[1].membership === 'bookie' || book[1].membership === 'admin');
            },
            options() {
                return MANAGE_OPTIONS.map(option => {
                    return { ...option, params: { book_id: this.$route.params.book_id } };
                });
            },
        },
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    #CreateWager {
        padding: size(Large) 0 50px;
    }

</style>
